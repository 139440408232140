import React from 'react';
import { useTranslation } from 'react-i18next';
import './Header.css';

function Header() {
    const { t } = useTranslation();

    return (
        <div className="header">
            <div className="header-left">
                <img src="dh-header-logo.png" alt="Druckhaus-Logo" style={{ height: "50px", width: "auto" }} />
            </div>
            <div className="header-mid">
                {/* Mid place holder for later*/}
            </div>
            <div className="header-right">
                <p className='menu'>
                    <span className="pipe"> | </span>
                    <a href='https://www.druckhaus.co/'>
                        {t('home-btn')}
                    </a>
                    <span className="pipe"> | </span>
                    <a href='https://www.copyshop-bochum.de/#contact'>
                        {t("contact-btn")}
                    </a>
                    <span className="pipe"> | </span>
                </p>
            </div>
        </div>
    );
}

export default Header;