import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

function Footer() {
    const { t } = useTranslation();
    return (
        <div className="footer">
            <p>
                <a href="http://www.copyshop-bochum.de/agb/">{t("AGB")}</a>
                <span className='pipe'>|</span>
                <a href="http://www.copyshop-bochum.de/impressum/">{t("impressum")}</a>
                <span className='pipe'>|</span>
                <a href="http://www.copyshop-bochum.de/datenschutz/">{t("data-protection")}</a>
                <br />
                Copyright © 2024 Druckhaus Bochum GmbH
            </p>
        </div>
    );
}

export default Footer;