import React, { useState } from 'react';
import './Menu-for-plot-size.css';

interface SizeMenuProps {
  onSizeChange: (size: string) => void;
  isMenuOpen: boolean;
}

function SizeMenu({ onSizeChange, isMenuOpen }: SizeMenuProps) {
  const [selectedSize, setSelectedSize] = useState<string | null>(null);

  const sizes = ['A0', 'A1', 'A2', 'A3'];

  const handleSizeClick = (size: string) => {
    setSelectedSize(size);
    onSizeChange(size);
  };

  if (!isMenuOpen) {
    return null;
  }

  return (
    <div className="size-menu">
      {sizes.map((size) => (
        <div
          key={size}
          className="size-menu-item"
          onClick={() => handleSizeClick(size)}
        >
          {size}
        </div>
      ))}
    </div>
  );
}

export default SizeMenu;