import React, { useState, useEffect, useRef } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import './App.css';
import SizeMenu from './Menu-for-plot-size';
import { useTranslation } from 'react-i18next';

import i18n from "i18next";

import axios from 'axios';
import { off } from 'process';

// Add this line to ensure proper loading of PDFJS worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function App() {
  // translation usage
  const { t } = useTranslation();

  // State variables for tracking the dropped file, pixel count, and page number
  const [isDragging, setIsDragging] = useState(false);
  const [droppedFile, setDroppedFile] = useState<{
    file: File | null;
    name: string;
    type: string;
  }>({
    file: null,
    name: "",
    type: "",
  });
  const [pixelCount, setPixelCount] = useState<number>(0);
  const [totalPixels, setTotalPixels] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isFileValid, setIsFileValid] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Ref for the container element
  const containerRef = useRef(null);

  // pdf <page> element ref
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // State variable for button position
  const [buttonPosition, setButtonPosition] = useState<"top" | "bottom">("bottom");

  // Event handlers for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // State variables for container and page dimensions
  const [containerDimensions, setContainerDimensions] = useState({
    width: window.innerWidth * 0.75,
    height: window.innerHeight * 0.75,
  });
  const [pageDimensions, setPageDimensions] = useState({
    width: containerDimensions.width * 0.5, // Set Page width to half of the container width
    height: containerDimensions.height,
  });

  //-> for MENU and further calculations
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [isColor, setIsColor] = useState(true);
  const [price, setPrice] = useState<number | null>(null);

  const handlePlotSizeChange = (size: string) => {
    setSelectedSize(size);
    setMenuOpen(false);
  };


  // This function handles the drop event for file drag-and-drop.
  // It validates the file type, updates the state with file information,
  // and if it's a PDF, it loads the PDF. If it's an image, it calculates the non-white pixel count.
  // It also manages error messages based on file validity.
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    resetSizeSelection();

    const files = e.dataTransfer.files;

    if (files.length === 1) {
      const file = files[0];
      setPrice(null);

      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        setDroppedFile({
          file: file,
          name: file.name,
          type: file.type,
        });

        // Load PDF and set number of pages
        if (file.type === "application/pdf") {
          const reader = new FileReader();
          reader.onload = (event) => {
            const pdfData = event.target?.result; // this is typed as ArrayBuffer

            const loadingTask = pdfjs.getDocument({ data: pdfData as Uint8Array });
            loadingTask.promise.then((pdf) => {
              setNumPages(pdf.numPages);
              setPageNumber(1);
            });
          };
          reader.readAsArrayBuffer(file);
        } else {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0);
            const imageData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
            let totalPixels = 0;
            let nonWhitePixels = 0;
            for (let i = 0; i < imageData.data.length; i += 4) {
              totalPixels++;
              if (
                imageData.data[i] !== 255 ||
                imageData.data[i + 1] !== 255 ||
                imageData.data[i + 2] !== 255 ||
                imageData.data[i + 3] !== 255
              ) {
                // Skip transparent pixels
                if (imageData.data[i + 3] !== 0) {
                  nonWhitePixels++;
                }
              }
            }
            setPixelCount(nonWhitePixels);
            setTotalPixels(totalPixels);
          };
          img.src = URL.createObjectURL(file);
          setNumPages(1);
          setPageNumber(1);
        }
        setIsFileValid(true); // Set isFileValid to true if the file is valid
        setErrorMessage(null); // Clear the error message if the file is valid

        // Send the file type as a message using the Telegram Bot API
        const message = `File type: ${file.type}`;
      } else {
        setIsFileValid(false); // Set isFileValid to false if the file is invalid
        alert(t('file-type-alert'));
      }
    } else {
      setIsFileValid(false); // Set isFileValid to false if the file is invalid
      alert(t('file-number-alert'));
    }
  };


  // This function handles the file upload event.
  // It gets the first file from the input event, wraps it in an array, and then
  // calls the handleDrop function with a mock DragEvent that contains the file.
  // This is done to reuse the handleDrop function for both drag-and-drop and file input events.
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const files = [file];
      handleDrop({
        preventDefault: () => { }, dataTransfer: { files }
      } as unknown as React.DragEvent<HTMLDivElement>);
    }
  };


  // Event handler for reparing the application state for the newly loaded document.
  // It sets the number of pages in the state to the number of pages in the loaded document,
  // resets the page number to 1, resets the price to null, and resets the size selection.
  const handleDocumentLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setPrice(null);
    resetSizeSelection();
  };


  // This function handles the loading of a invisible pdf page. 
  // It renders the page off-screen in orig size.
  // then counts the number of non-white pixels and sets the pixel count in the state.
  const handlePageLoaded = async (page: any) => {
    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = page.width;
    offScreenCanvas.height = page.height;
    const offScreenCtx = offScreenCanvas.getContext('2d');

    const viewport = page.getViewport({ scale: 1, rotation: 0 });
    const renderContext = {
      canvasContext: offScreenCtx,
      viewport: viewport
    };
    await page.render(renderContext).promise;

    const imageData = offScreenCtx!.getImageData(0, 0, page.width, page.height);
    let totalPixels = 0;
    let nonWhitePixels = 0;
    for (let i = 0; i < imageData.data.length; i += 4) {
      totalPixels++;
      if (
        imageData.data[i] !== 255 ||
        imageData.data[i + 1] !== 255 ||
        imageData.data[i + 2] !== 255 ||
        imageData.data[i + 3] !== 255
      ) {
        nonWhitePixels++;
      }
    }
    setPixelCount(nonWhitePixels);
    setTotalPixels(totalPixels);
  };

  // This effect runs whenever pixelCount or totalPixels changes
  useEffect(() => {
    calculatePrice();
  }, [pixelCount, totalPixels]);

  // This effect sets up an observer that updates the page dimensions in the state
  // whenever the size of the container changes.
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries[0] && entries[0].contentRect) {
        const { width, height } = entries[0].contentRect;
        setPageDimensions({
          width: width * 0.5,
          height: height * 0.5
        });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);


  // Calculate percentage of non-white pixels
  const percentage = totalPixels > 0 ? ((pixelCount / totalPixels) * 100).toFixed(2) : 0;


  // This function handles file input changes. 
  // It validates the file type, updates the state with file information,
  // triggers the file upload handler, and if it's a PDF, it loads the PDF. 
  // It also manages error messages based on file validity.
  const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file && (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg")) {
      setDroppedFile({
        file: file,
        name: file.name,
        type: file.type,
      });
      handleFileUpload(e);
      if (file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const pdfData = event.target?.result;
          const loadingTask = pdfjs.getDocument({ data: pdfData as Uint8Array });
          loadingTask.promise.then((pdf) => {
            setNumPages(pdf.numPages);
            setPageNumber(1);
          });
        };
        reader.readAsArrayBuffer(file);
      }
      setIsFileValid(true); // Set isFileValid to true if the file is valid
      setErrorMessage(null); // Clear the error message if the file is valid
    } else {
      setIsFileValid(false); // Set isFileValid to false if the file is invalid
      setErrorMessage(t('file-type-alert'));
    }
  };


  // Reset selected Din A plot size to initial value while changing pages
  const resetSizeSelection = () => {
    setSelectedSize('');
  };


  // Calculate price
  const calculatePrice = () => {
    if (selectedSize && percentage) {
      const basePrice = 0;
      const percentageInt = parseInt(percentage);
      if (selectedSize === 'A3') {
        if (isColor) setPrice(0.5 + basePrice);
        else setPrice(0.10 + basePrice);
      }
      if (percentageInt < 20) {  // CAD
        if (isColor) {
          if (selectedSize === 'A0') setPrice(7.0 + basePrice);
          else if (selectedSize === 'A1') setPrice(5.0 + basePrice);
          else if (selectedSize === 'A2') setPrice(3.0 + basePrice);
        }
        else {
          if (selectedSize === 'A0') setPrice(6.0 + basePrice);
          else if (selectedSize === 'A1') setPrice(4.0 + basePrice);
          else if (selectedSize === 'A2') setPrice(2.0 + basePrice);
        }
      }
      else if (percentageInt < 40) {   // Grafik
        if (isColor) {
          if (selectedSize === 'A0') setPrice(11.0 + basePrice);
          else if (selectedSize === 'A1') setPrice(9.0 + basePrice);
          else if (selectedSize === 'A2') setPrice(7.0 + basePrice);
        }
        else {
          if (selectedSize === 'A0') setPrice(9.0 + basePrice);
          else if (selectedSize === 'A1') setPrice(7.0 + basePrice);
          else if (selectedSize === 'A2') setPrice(5.0 + basePrice);
        }
      }
      else { // Poster
        if (isColor) {
          if (selectedSize === 'A0') setPrice(16.0 + basePrice);
          else if (selectedSize === 'A1') setPrice(12.0 + basePrice);
          else if (selectedSize === 'A2') setPrice(8.0 + basePrice);
        }
        else {
          if (selectedSize === 'A0') setPrice(14.0 + basePrice);
          else if (selectedSize === 'A1') setPrice(10.0 + basePrice);
          else if (selectedSize === 'A2') setPrice(6.0 + basePrice);
        }
      }
    }
  };


  // Price calculation caller
  useEffect(() => {
    calculatePrice();
  }, [selectedSize, isColor]);


  // Use effect hook for updating button position
  useEffect(() => {
    if (droppedFile.file) {
      setButtonPosition("top");
    } else {
      setButtonPosition("bottom");
    }
  }, [droppedFile]);


  // Prevent right-click
  // Prevent selection
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    document.addEventListener("selectstart", (e) => {
      e.preventDefault();
    });
  }, []);


  // Render the app
  return (
    <div className="app">
      {/* Add your name and beta version message here */}
      <Header />
      <div
        className="container"
        ref={containerRef}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* Left side of the container */}

        <div className={`left ${isDragging ? "dragging" : ""} ${errorMessage ? "invalid" : isFileValid ? "valid" : ""}`}>
          {/* Add the upload button */}
          <button className={`upload-button ${buttonPosition}`} onClick={() => {
            const fileInput = document.getElementById("file-input");
            if (fileInput) {
              fileInput.click();
            }
          }}>
            {t('upload-file-types')}
          </button>
          {droppedFile.file ? (
            <>
              {/* Add the navigation div */}
              <div className="navigation">
                <button onClick={() => {
                  setPageNumber(Math.max(pageNumber - 1, 1));
                }}>
                  {"<"}
                </button>
                <span>
                  {t('page')} {pageNumber} {t('of')} {numPages}
                </span>
                <button onClick={() => {
                  setPageNumber(Math.min(pageNumber + 1, numPages));
                }}>
                  {">"}
                </button>
              </div>

              {/* start ----------- File information -----------*/}
              <div className="left-container-informationholder">
                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '33%', marginLeft: '10px', textAlign: 'left' }}>Dateiname</td>
                    <td style={{ width: '33%' }}>
                      <div style={{
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '90%'
                      }}
                        title={droppedFile.name.split('.').slice(0, -1).join('.')}
                      >
                        {droppedFile.name.split('.').slice(0, -1).join('.')}
                      </div>
                    </td>
                    <td style={{ width: '33%', marginLeft: '10px', textAlign: 'right' }}>.{droppedFile.type.split('/').pop()}</td>
                  </tr>
                </table>

                <table className='result-main-table'>
                  <tr style={{}}>
                    <td style={{ width: '50%', marginLeft: '10px', textAlign: 'left', borderBottom: '1px solid #f2f2f2' }}>Pixelabdeckung:</td>
                    <td style={{ width: '50%', borderBottom: '1px solid #f2f2f2' }}>
                      <p className='result-to-show'>{percentage}%</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%', marginLeft: '10px', textAlign: 'left' }}>Preiskategorie:</td>
                    <td style={{ width: '50%' }}>
                      <p className='result-to-show'>
                        {Number(percentage) <= 20 ? "Linienbetont" : (Number(percentage) <= 50 ? "Teildeckend" : "Vollflächig")}
                      </p>
                    </td>
                  </tr>
                </table>

                <p className='percentage-info-text'>
                  Bis 20% Linienbetont<br></br>21% - 50% Teildeckend &nbsp;&nbsp;|&nbsp;&nbsp; ab 51% Vollflächig
                </p>
                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '30%', textAlign: 'left' }}>Größe:</td>
                    <td style={{ width: '30%' }}>
                      <div style={{ marginLeft: '-18px' }}>
                        {!isMenuOpen && <p className='size-menu' onClick={() => setMenuOpen(true)}>Wähle aus</p>}
                        {isMenuOpen && <SizeMenu onSizeChange={handlePlotSizeChange} isMenuOpen={isMenuOpen} />}
                      </div>
                    </td>
                    <td style={{ width: '30%', textAlign: 'left' }}>
                      <p className='result-to-show'>{selectedSize}</p>
                    </td>
                  </tr>
                </table>

                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '30%', textAlign: 'right' }}>
                      <p className="gray-gradient">S/W</p>
                    </td>
                    <td className="table-cell">
                      <label className="switch">
                        <input type="checkbox"
                          defaultChecked={true}
                          onChange={(e) => {
                            setIsColor(e.target.checked);
                            setPrice(null);
                          }
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td style={{ width: '30%', textAlign: 'left' }}>
                      <p className="rainbow">Farbig</p>
                    </td>
                  </tr>
                </table>

                <table className='result-main-table'>
                  <tr>
                    <td style={{ width: '50%', marginLeft: '10px', textAlign: 'left' }}>Preis:</td>
                    <td style={{ width: '50%', textAlign: 'right' }}>
                      <p className='result-to-show'>{price ? price.toFixed(2) : '-'}€ </p>
                    </td>
                  </tr>
                </table>
              </div>
              {/* End----------- File information -----------*/}

              {/* <div style={{ alignItems: 'center' , marginTop: '10px', marginBottom: '20px'}}>
              <button className='size-menu' onClick={calculatePrice}>Berechnen</button>
            </div> */}
              <div style={{ alignItems: 'center', margin: '15px' }}>
                <button onClick={() => window.location.reload()}>{t('refresh-page')}</button>
              </div>
            </>
          ) : (
            <p className='drag-here-text'> {t("drag-msg")}</p>
          )}
          {/* Add the error message */}
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
        {/* Right side of the container */}
        <div className="right" style={{ margin: "10px" }}>
          <div className="pdf-container">
            {droppedFile.file ? (
              <>
                {/* Render the PDF */}
                {droppedFile.type === "application/pdf" ? (
                  <Document className={"pdf-loader"} file={droppedFile.file} onLoadSuccess={handleDocumentLoad}>

                    <div className='pdf-orig-hiddden-size'>
                      {/* this is just for calculations, I coullnd't find a better way to do it  */}
                      <Page
                        pageNumber={pageNumber}
                        onLoadSuccess={handlePageLoaded}
                      />
                    </div>
                    <div style={{}}>
                      <Page
                        pageNumber={pageNumber}
                        width={pageDimensions.width * 0.9}
                        height={pageDimensions.height * 0.9}
                      />
                    </div>
                  </Document>
                ) : (
                  <img
                    className={"other-image"}
                    src={droppedFile.file ? URL.createObjectURL(droppedFile.file) : ""}
                    alt=""
                    style={{ maxWidth: pageDimensions.width * 0.9 }}
                  />
                )}
              </>
            ) : (
              <p className='welcome-text'>
                {t("welcome-txt").split('\n').map((line: string, i: number) => <span key={i}>{line}<br /></span>)}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* Add the German message here */}


      <Footer />
      <input id="file-input" type="file" accept=".pdf,.png,.jpg,.jpeg" onChange={handleFileInputChange} style={{ display: "none" }} />
    </div>
  );
}

export default App;
